import { css, ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import { ReactLenis } from '@studio-freight/react-lenis'
import React, { memo, Suspense, useEffect, useState } from 'react'

import { MediaContextProvider, mediaStyle } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      neutralLight4: '#FFFFFF',
      neutralLight3: '#261919',
      neutralLight2: '#211515',
      neutralLight1: '#604343',
      neutralDark5: '#232B22',
      neutralDark4: '#6E6E6E',
      neutralDark3: '#363636',
      neutralDark2: '#1D1212',
      neutralDark1: '#000000',
      primaryDark: '#FF4D00',
      danger: '#F23030',
    },
  },
  fontFamily: {
    heading: "'new-science-extended', sans-serif",
    paragraph: "'inter-variable', sans-serif",
  },
}

export type Styles = typeof styles

const titleStyle = css`
  color: ${theme.colors.variants.neutralLight4};
  font-family: ${theme.fontFamily.heading};
  text-transform: uppercase;
`

const smallTitleStyle = css`
  color: ${theme.colors.variants.neutralLight4};
  font-family: ${theme.fontFamily.paragraph};
`

export const styles = {
  label: css`
    color: ${theme.colors.variants.neutralLight4};
    font-family: ${theme.fontFamily.paragraph};
    font-size: 0.9375rem;
    line-height: 1.1875rem;
    letter-spacing: 0.2em;
    text-transform: uppercase;
  `,
  title: {
    xl: css`
      ${titleStyle}
      font-size: 3rem;
      line-height: 3.625rem;

      @media (max-width: 1199px) {
        font-size: 2rem;
        line-height: 2.375rem;
      }
    `,
    large: css`
      ${titleStyle}
      font-size: 2.625rem;
      line-height: 2.875rem;

      @media (max-width: 1199px) {
        font-size: 1.75rem;
        line-height: 2.125rem;
      }
    `,
    medium: css`
      ${titleStyle}
      font-size: 2rem;
      line-height: 2.375rem;

      @media (max-width: 1199px) {
        font-size: 1.5rem;
        line-height: 1.75rem;
      }
    `,
    normal: css`
      ${smallTitleStyle}

      font-weight: 200;
      font-size: 1.25rem;
      line-height: 1.75rem;
      letter-spacing: 0.05em;

      @media (max-width: 1199px) {
        font-size: 1.25rem;
        line-height: 1.75rem;
      }
    `,
    xm: css`
      ${smallTitleStyle}
      font-size: 1rem;
      line-height: 1.25rem;
      text-transform: uppercase;

      @media (max-width: 1199px) {
        font-size: 1rem;
        line-height: 1.25rem;
      }
    `,
    small: css`
      ${smallTitleStyle}
      font-size: 0.75rem;
      line-height: 0.9375rem;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    `,
  },
  description: css`
    color: ${theme.colors.variants.neutralLight4};
    font-family: ${theme.fontFamily.paragraph};
    font-size: 1rem;
    line-height: 1.375rem;
    font-weight: 200;
  `,
  img: css`
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    will-change: contents;
  `,
}

interface Props {
  children: any
  fallback?: any
  pageContext: any
}

export const ThemeProvider = memo(function ThemeProvider({
  children,
  fallback,
}: Props) {
  const isBrowser = typeof window !== 'undefined'

  let smoothScroll = false
  if (isBrowser) smoothScroll = window.innerWidth > 1199 ? true : false

  const [isMounted, setMounted] = useState<boolean>(false)

  useEffect(() => {
    if (!isMounted) {
      setMounted(true)
    }
  }, [isBrowser])

  return (
    <EmotionThemeProvider theme={theme}>
      <Suspense fallback={fallback}>
        {!isMounted ? (
          fallback
        ) : (
          <>
            <style type="text/css">{mediaStyle}</style>
            <MediaContextProvider>
              {smoothScroll ? (
                <ReactLenis root options={{ duration: 1.4 }}>
                  {children}
                </ReactLenis>
              ) : (
                children
              )}
            </MediaContextProvider>
          </>
        )}
      </Suspense>
    </EmotionThemeProvider>
  )
})
